import httpService from './httpService';
const { statementEndpoint, newStatementEndpoint } = ApplicationSettings;

class StatementService {
	constructor(httpService) {
		this.httpService = httpService;
	}
	getHeaders = mid => {
		let headers = new Headers();
		if (mid) {
			headers.set('x-merchant-id', mid);
		}
		return headers;
	};

	getOptions = mid => {
		return {
			isJson: true,
			headers: this.getHeaders(mid),
		};
	};

	loadStatements = async mid => {
		let url = `${statementEndpoint}liststatements`;
		let body = {};
		if(window.location.href.includes('statements/merchant-processing-statements')) {
			url = `${newStatementEndpoint}liststatements`;
			body = { statementType: 'GoPlusMerchantStatement' };
		}
		const result = await this.httpService.post(
			url,
			body,
			{
				...this.getOptions(mid),
				allowPublic: true,
			}
		);

		return result;
	};
	loadStatement = async (mid, year, filename) => {
		let url = `${statementEndpoint}loadstatement`;
		let body = { Year: year, FileName: filename };
		if(window.location.href.includes('statements/merchant-processing-statements')) {
			url = `${newStatementEndpoint}loadstatement`;
			body.statementType = 'GoPlusMerchantStatement';
		}
		const result = await this.httpService.post(
			url,
			body,
			{
				...this.getOptions(mid),
				allowPublic: true,
			}
		);

		return result;
	};
}

const statementService = new StatementService(httpService);

export default statementService;

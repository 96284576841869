import principalService from 'common/services/principalService';
import { publicLayout, cardholderUpdaterLayout, UpgradePlanLayout } from '../layouts';
import { TransactionGrid } from 'components/transaction-grid';
import {
	LoginComponent,
	LogoutComponent,
	RegisterComponent,
	ForgotPasswordComponent,
	ConfirmMFAComponent,
	ConfirmRegistrationComponent,
	ConfirmNewPasswordComponent,
	ChangePasswordComponent,
	TermsAndConditionsModal,
	Security,
} from 'components/login';
import SamlLoginComponent from 'components/login/saml-login';
import { InfoComponent } from 'components/info';
import { Dashboard } from 'components/dashboard';
import { BatchIndex } from 'components/batch';
import { ApiKeys } from 'components/api-keys';
import { Reports } from 'components/reports';
import { Fraudwatch } from 'components/fraudwatch';
import { GiftReport } from 'components/gift-report';
import { SendPaymentRequest } from 'components/send-payment-request';
import sectionKeys from './sections';
import { CardholderUpdater } from 'components/cardholder-updater';
import { DisputesGrid } from 'components/disputes-grid';
import { PortalManagementList, PortalManagementAll } from 'components/portal-management';
import UpgradePlan from 'components/upgrade-plan/UpgradePlan';
import TerminalOnlyUpgradePlan from 'components/upgrade-plan/TerminalOnlyUpgradePlan';
import NewTransactionVirtualTerminal from 'components/new-transaction/NewTransactionVirtualTerminal';

import GiftBalance from 'components/gift-report/GiftBalance';
import UserSettings from 'components/user-settings/UserSettings';
import AccountManagement from 'components/account-management/AccountManagement';
import { FeatureAddOns } from 'components/feature-add-ons';
import { Transfers } from 'components/transfers';
import { SolaAccountSettingsComponent } from 'components/settings/pages';
import { get } from 'lodash';
import SettingsContainer from 'components/settingsContainer/SettingsContainer';
import Customers from 'components/customers/Customers';
import RecurringSchedules from 'components/recurring-schedules/RecurringSchedules';
import upgradeNotification from 'components/login/upgrade-notification';
import StatementsTabs from 'components/statements-grid/StatementsTabs';

let principal = principalService.get();
principalService.subscribe(newPrincipal => {
	principal = newPrincipal;
});

const routes = [
	{
		path: '/saml-login',
		component: SamlLoginComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/login',
		component: LoginComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/logout',
		component: LogoutComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/register',
		component: RegisterComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/register2',
		component: RegisterComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/forgot-password',
		component: ForgotPasswordComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/confirm-registration',
		component: ConfirmRegistrationComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/security',
		component: Security,
		isPublic: () => principal === null || get(principal, 'redirectToSecurity', false),
		section: 'DOES NOT EXIST - SERVES TO PREVENT LOGGED IN USERS FROM ACCESSING PUBLIC SECURITY PAGE',
	},
	{
		path: '/confirm-mfa',
		component: ConfirmMFAComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/confirm-new-password',
		component: ConfirmNewPasswordComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/change-password',
		component: ChangePasswordComponent,
		layout: publicLayout,
		isPublic: true,
	},
	{
		path: '/info',
		component: InfoComponent,
		layout: publicLayout,
		isPublic: true,
		customLayoutClass: 'auth--info',
	},
	{
		path: '/api-keys',
		component: ApiKeys,
	},
	{
		path: '/',
		exact: true,
		component: Dashboard,
		section: sectionKeys.dashboard,
		isDefault: () =>
			principal &&
			principal.hasAccess &&
			principal.hasAccess[sectionKeys.dashboard] &&
			principal.acceptedTermsAndConditions,
	},
	{
		path: '/transactions',
		component: TransactionGrid,
		section: [sectionKeys.dashboard, sectionKeys.transactions],
	},
	{
		path: '/new-transaction',
		component: NewTransactionVirtualTerminal,
		section: sectionKeys.virtualTerminal,
	},
	{
		path: '/transfers',
		component: Transfers,
		section: [sectionKeys.dashboard, sectionKeys.transfers],
	},
	{
		path: '/batches',
		component: BatchIndex,
		section: sectionKeys.batches,
	},
	{
		path: '/reports',
		component: Reports,
		section: [sectionKeys.dashboard, sectionKeys.transactions],
	},
	{
		path: '/giftreport',
		component: GiftReport,
		section: [sectionKeys.dashboard, sectionKeys.gift],
	},
	{
		path: '/balance',
		component: GiftBalance,
		isPublic: true,
	},
	{
		path: '/disputes',
		component: DisputesGrid,
		section: [sectionKeys.dashboard, sectionKeys.transactions],
	},
	{
		path: '/customers',
		component: Customers,
		section: sectionKeys.customers,
		isDefault: () =>
			principal &&
			principal.hasAccess &&
			principal.hasAccess[sectionKeys.customers] &&
			principal.acceptedTermsAndConditions,
	},
	{
		path: '/recurring-schedules',
		component: RecurringSchedules,
		section: sectionKeys.dashboard,
	},
	{
		path: '/fraudwatch',
		component: Fraudwatch,
		section: sectionKeys.fraud,
		isDefault: () =>
			principal &&
			principal.hasAccess &&
			principal.hasAccess[sectionKeys.fraud] &&
			principal.acceptedTermsAndConditions,
	},
	{
		path: '/send-payment-request',
		component: SendPaymentRequest,
		section: sectionKeys.newTransaction,
	},
	{
		path: '/settings',
		component: SettingsContainer,
	},
	{
		path: 'settings/user-settings',
		component: UserSettings,
	},
	{
		path: 'settings/portal-settings',
		component: AccountManagement,
		section: sectionKeys.settings,
	},
	{
		path: 'settings/gateway-settings',
		component: SolaAccountSettingsComponent,
		section: sectionKeys.solaAccountSettings,
	},
	{
		path: '/portal-management/all',
		component: PortalManagementAll,
		section: sectionKeys.portalManagement,
	},
	{
		path: '/portal-management',
		component: PortalManagementList,
		section: sectionKeys.portalManagement,
	},
	{
		path: '/terms-and-conditions',
		layout: publicLayout,
		component: TermsAndConditionsModal,
		isDefault: () => principal && !principal.acceptedTermsAndConditions,
	},
	{
		path: '/merchant-portal-upgrade',
		component: upgradeNotification,
	},
	{
		path: '/cardholder-updater',
		layout: cardholderUpdaterLayout,
		component: CardholderUpdater,
		isPublic: true,
	},
	{
		path: '/upgrade-plan',
		layout: UpgradePlanLayout,
		component: UpgradePlan,
	},
	{
		path: '/terminal-only',
		component: TerminalOnlyUpgradePlan,
	},
	{
		path: '/features',
		component: FeatureAddOns,
		section: sectionKeys.featureAddons,
	},
	{
		path: '/statements',
		component: StatementsTabs,
		section: sectionKeys.statements,
	},
];

export default routes;
